import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import jwtDecode from 'jwt-decode';
import Login from './login';
import { navigate } from 'gatsby';
import { isQRTokenValid } from '../../helpers/tokenUtils';

const WeddingQr = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const brideId = searchParams.get('bride');
  const groomId = searchParams.get('groom');

  useEffect(() => {
    let token = null;
    if (localStorage.getItem('Token')) {
      token = localStorage.getItem('Token');
    }
    console.log('token', token);
    if (token && isQRTokenValid(token)) {
      navigate(`/weddingQR/Side/${location.search}`);
    } else {
      navigate(`/weddingQR/login/${location.search}`);
    }
  }, [location.search]);

  return (
    <div>
      {/* <h1>Wedding of Groom Name and Bride name </h1> */}
      {/* <Login /> */}
      <Login />
    </div>
  );
};

export default WeddingQr;
